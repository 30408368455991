import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


//import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import OutlinedFlagOutlinedIcon from '@mui/icons-material/OutlinedFlagOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingBasketOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';



const MenuItemLink = (props) => {
    const icon= props.icon==='DraftsOutlinedIcon'?<DraftsOutlinedIcon/>:props.icon==='SettingsOutlinedIcon'?<SettingsOutlinedIcon/>:props.icon==='SmsOutlinedIcon'?<SmsOutlinedIcon/>:props.icon==='ShoppingBasketOutlinedIcon'?<ShoppingBasketOutlinedIcon/>:props.icon==='ShoppingCartIcon'?<ShoppingCartIcon/>:
    props.icon==='PersonAddAlt1OutlinedIcon'?<PersonAddAlt1OutlinedIcon/>:props.icon==='OutlinedFlagOutlinedIcon'?<OutlinedFlagOutlinedIcon/>:props.icon==='AccountBalanceWalletOutlinedIcon'?<AccountBalanceWalletOutlinedIcon/>:props.icon==='TouchAppOutlinedIcon'?<TouchAppOutlinedIcon/>:props.icon==='DesktopWindowsIcon'?<DesktopWindowsIcon/>:
    props.icon==='SettingsInputAntennaIcon'?<SettingsInputAntennaIcon/>:'';

  //  const icons =[TouchAppOutlinedIcon , AccountBalanceWalletOutlinedIcon , OutlinedFlagOutlinedIcon , PersonAddAlt1OutlinedIcon , ShoppingBasketOutlinedIcon,DraftsOutlinedIcon,SettingsOutlinedIcon,SmsOutlinedIcon]
// let icons = props.icon ;
    const {t} = useTranslation();
    return ( 
      <Link className="nav-link" to={{pathname:props.url?props.url:props.to}} onClick={(e)=>props.mainClick(props.title,props.url,e,props.to)}>

         

             
              <i className="menu-icon">{icon} </i>
             

         
            <span className="menu-title">{t('Sidemenu.'+props.title)}</span>

        </Link>
     );
}
 
export default MenuItemLink;