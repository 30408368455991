import React from "react";
import { Container } from "react-bootstrap";
import style from "../style/App.module.scss";
import { Language } from "../components/common/";
import Brand from "../components/auth/Brand";
import { useSelector } from "react-redux";
import AppInfoservice from "../../service/common/Appinfo";
import { useState,useEffect } from "react";
function AuthLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service=new AppInfoservice();
  const [cname, setcname] = useState("")
  useEffect(()=>{
    service.Appinfo().then((res)=>{
      if(res.status){
        setcname(res.data.company_info.company_name)
      }
    })
  },[])
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_app} pt-5`
          : `${style.app} pt-5`
      }`}
    >
      <Container
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_loginContainer}`
            : `${style.loginContainer}`
        }`}
      >
        <Brand />
        <Language classname="mb-4 w-100" />
        {children}
      </Container>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_lg_login_btn_btm_1}`
            : `${style.lg_login_btn_btm_1}`
        }`}
      >
        2022 © {cname}
      </div>
    </div>
  );
}

export default AuthLayout;
